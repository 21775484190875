<template>
  <el-dialog
    title="任务执行中"
    :visible.sync="showDialog"
    :before-close="cancel"
    width="50%">
    <div class="overlay">
      <div class="overlay-content">
        <i class="el-icon-loading"></i>
        <el-progress :percentage="percentage"></el-progress>
      </div>
    </div>
  </el-dialog>
</template>
<script>

export default {
  name: 'McProject',
  props: {},
  data () {
    return {
      showDialog: false,
      // 总条数
      total: 0,
      // 已处理条数
      handle: 0,
      // 总进度
      percentage: 0
    }
  },
  created () {
  },
  methods: {
    // 初始化条数
    init: function (total) {
      this.handle = 0
      this.percentage = 0
      this.total = total
      this.showDialog = true
    },
    // 累加
    setInc: function () {
      if (this.percentage === 100) return
      this.handle += 1
      this.percentage = Math.ceil(this.handle / this.total * 100)
    },
    // 关闭弹窗
    cancel: function () {
      this.showDialog = false

      // project
    }
  }
}
</script>

<style scoped lang="less">
.overlay {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  height: 25vw;
  z-index: 9999;
}

.overlay-content {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.el-progress {
  width: 20vw;
}
</style>
