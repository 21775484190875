<template>
  <div class="manage">
    <!-- 搜索区域 -->
    <McProject ref="project"></McProject>
    <div style="display: flex;flex-direction: row;">
      <el-button size="mini" class="el-icon-download" type="info" plain @click="downloadClick">下载模版</el-button>
      <el-select v-model="TeacherId" placeholder="请选择老师">
        <el-option
          v-for="(item, index) in Teacher"
          :key="index"
          :label="item.Name"
          :value="item.Id">
        </el-option>
      </el-select>
      <el-upload action="#" :show-file-list="false" :http-request="importClick">
        <el-button size="mini" class="el-icon-upload" type="info" plain>导入</el-button>
      </el-upload>
      <el-button @click="webSiteClick" size="mini" type="primary">官网导入</el-button>
      <el-button @click="baoTaClick" size="mini" type="primary">宝塔建站</el-button>
      <el-button @click="mailClick" size="mini" type="primary">添加邮箱</el-button>
<!--      <el-button @click="addClick" size="mini" type="primary">添加邮箱</el-button>-->
    </div>

    <div class="center-container">
      <!-- 表格 -->
      <el-table
        :data="List"
        style="width: 80%;"
        border
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="TitleCn" label="名称"/>
        <el-table-column prop="Domain" label="官网"/>
        <el-table-column prop="Email" label="邮箱"/>
        <el-table-column prop="Identifying" label="简称"/>
        <!--      <el-table-column label="操作">-->
        <!--        <template v-slot="{ row }">-->
        <!--          <el-button size="mini" @click="deleteClick(row)" type="danger">删除</el-button>-->
        <!--        </template>-->
        <!--      </el-table-column>-->
      </el-table>

      <el-input v-model="message" style="width: 20%;" type="textarea" :autosize="{ minRows: 30 }"></el-input>
    </div>
  </div>
</template>

<script>
import Service from '@/service/Business/ImportService'

export default Service
</script>

<style lang="less" scoped>
.manage {
  height: 90%;

  .manage-header {
    width: 40vw;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
}

.center-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

</style>
